import axios from 'axios';
import qs from 'qs';
import { createPinia } from 'pinia';
import { setDefaultProps } from 'vue-tippy';
import { createInertiaApp, router } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import * as Sentry from '@sentry/vue';
import Sortable, { Swap } from 'sortablejs';

import I18nVue, { currentLocale, currentLocaleTag } from '@aspect/shared/plugins/i18n.ts';
import useContext from '@aspect/shared/composables/use-context.ts';
import useNotification from '@aspect/shared/composables/use-notification.ts';
import { setLocale as setDateLocale, setTimezone } from '@aspect/shared/utils/date.ts';
import NotificationsVue from '@aspect/shared/plugins/notifications.ts';
import { ModalPlugin } from '@aspect/shared/plugins/modal.ts';

import { InlineModalPlugin } from '@aspect/shared/plugins/inline-modal.ts';

import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';
import LayoutAuth from '@/app/layouts/auth/layout-auth.vue';
import LayoutMain from '@/app/layouts/main/layout-main.vue';

import '@/app/css/main.css';

import type { DefineComponent } from 'vue';

const pageResolver = (name: string) => {
    return resolvePageComponent(
        `/resources/app/pages/${name}.vue`,
        import.meta.glob<DefineComponent>([
            '/resources/app/pages/**/page.vue',
            '/resources/app/pages/**/modal.vue',
        ]),
    );
};

let appIsMounted = false;
const pinia = createPinia();

setDefaultProps({
    arrow: false,
    animation: 'translate',
    offset: [0, 8],
    touch: true,
    theme: 'light',
});

export const pageTitle = window.document.getElementsByTagName('title')[0]?.innerText;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.request.use((config: any) => {
    const url = new URL(config.url);
    const parsed = qs.parse(url.search, { ignoreQueryPrefix: true });

    url.search = qs.stringify(parsed, { arrayFormat: 'brackets', encode: false });
    config.url = url.href;

    return config;
}, (error) => {
    return Promise.reject(error);
});

Sortable.mount(new Swap());


createInertiaApp({
    progress: {
        color: '#4f79a8',
    },
    title: (title) => (title ? `${title} - ${pageTitle}` : pageTitle),
    resolve: async (name) => {
        const page = await pageResolver(name);
        const layout = name.startsWith('auth/') ? LayoutAuth : LayoutMain;

        if (page.default.layout === undefined) {
            page.default.layout = layout;
        }

        return page;
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({
            setup() {
                const { setContext } = useContext();
                const pageProps = usePageProps(props.initialPage.props);

                onBeforeMount(() => {
                    if (pageProps.value.user && pageProps.value.tenant) {
                        setContext({ user: pageProps.value.user, tenant: pageProps.value.tenant });
                    }

                    if (pageProps.value.tenant) {
                        setTimezone(pageProps.value.tenant.timezone);
                    }
                });

                watch(currentLocale, () => {
                    axios.defaults.headers.common['X-Locale'] = currentLocale.value;
                    setDateLocale(currentLocaleTag.value);
                }, { immediate: true });

                return () => h(App, props);
            },
        });

        const { notification, errors } = useNotification();

        router.on('finish', () => {
            const pageProps = usePageProps();

            notification.value = pageProps.value.notification;
            errors.value = pageProps.value.errors;
        });

        // Only put global plugins that are used in all app.
        app.use(plugin)
            .use(pinia)
            .use(I18nVue, {
                fallbackLang: 'en',
                resolve: async (lang: string) => {
                    const langs = import.meta.glob('../../../../packages/lang/src/*.json');
                    return await langs[`../../../../packages/lang/src/${lang}.json`]();
                },
                onLoad: () => {
                    if (appIsMounted) {
                        return;
                    }

                    app.mount(el); // Mounted here so translations are loaded before vue.
                    appIsMounted = true;
                },
            });

        if (import.meta.env.VITE_SENTRY_DSN) {
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN,
                integrations: [Sentry.browserTracingIntegration()],
                tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
                tracePropagationTargets: [
                    /^http:\/\/(app|auth)\.aspect\.localhost/,
                    /^https:\/\/(dev.)?(app|auth)\.aspect\.systems/,
                ],
                ignoreErrors: ['Network Error'],
            });
        }

        app.use(ModalPlugin, { resolve: (name) => pageResolver(name) })
            .use(InlineModalPlugin, { resolve: (name) => pageResolver(name) })
            .use(NotificationsVue, { position: 'top-center' });
    },
});
